<template>
  <Layout>
    <template v-if="row.label" v-slot:label>
      {{ row.label }}
    </template>
    <template v-if="row.extra" v-slot:extra>
      {{ row.extra }}
    </template>
    <template v-if="row.prepend" v-slot:prepend>
      {{ row.prepend }}
    </template>

    <template v-slot:input>
      <div class="flex items-center px-1 ml-1 bg-gray-600 rounded grow-0">
        <input
          v-model="privateFloatModel"
          :min="inputMin"
          :max="inputMax"
          :step="inputStep"
          class="block w-12 p-1 leading-tight text-right text-white bg-gray-600 border-2 border-gray-600 rounded-sm text-2xs focus:ring-opacity-50 focus:outline-none focus:bg-gray-600 focus:text-white" />

        <label v-if="row.units" class="dark:text-blue-400 text-2xs">{{ row.units }}</label>
      </div>
    </template>
  </Layout>
</template>
<script>
import inputs from './InputMixin'

export default {
  name: 'NumberInput',

  mixins: [inputs],

  computed: {
    isDisabled() {
      if (this.row.disabled) {
        return this.row.disabled
      }
      return false
    },

    inputMin() {
      if (this.row.min) return this.row.min
      return null
    },

    inputMax() {
      if (this.row.max) return this.row.max
      return null
    },

    inputStep() {
      if (this.row.step) return this.row.step
      return null
    }
  }
}
</script>
